<template>
  <Reassurance :blocks has-background />
</template>

<script lang="ts" setup>
import type { ReassuranceItem } from '@backmarket/http-api/src/api-specs-content/models/reinsurance'
import Reassurance from '@backmarket/nuxt-layer-cms/Reassurance.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { openModal } from '@ds/components/ModalBase'

import type { Features } from '~/features'

import { REASSURANCE } from '../../config/modals'

import translations from './TrustModalList.translations'

const i18n = useI18n()

const props = defineProps<{
  reviewsCount?: number
  reviewsRate?: number
}>()

const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

const scrollTo = (elementId: string) => {
  if (!elementId) return
  document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' })
}

type TrustModalItem = ReassuranceItem & {
  onClick?: () => void
}

const blocks: TrustModalItem[] = [
  {
    id: 'delivery',
    icon: 'IconTruck',
    title: i18n(translations.deliveryTitle),
    linkPosition: 'title',
  },
  {
    id: 'warranty',
    icon: 'IconQuality',
    title: i18n(translations.warrantyTitle),
    linkPosition: 'title',
    onClick: () => {
      openModal(REASSURANCE.WARRANTY)
    },
  },
  {
    id: 'return',
    icon: 'IconRefresh',
    title: i18n(translations.returnTitle, { days: coolingOffDays }),
    linkPosition: 'title',
    onClick: () => {
      openModal(REASSURANCE.SATISFACTION)
    },
  },
  {
    id: 'reviews',
    icon: 'IconStarOutlined',
    title: i18n(translations.reviewsTitle, {
      rate: parseFloat((props.reviewsRate || 0).toFixed(1)),
      count: props.reviewsCount,
    }),
    link: { href: '#customer-reviews' },
    linkPosition: 'title',
    onClick: () => {
      scrollTo('customer-reviews')
    },
  },
]
</script>
