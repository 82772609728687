<template>
  <div class="mb-20 grid grid-cols-1 gap-16 md:grid-cols-3 lg:grid-cols-4">
    <SwapBlockListing
      v-if="hasSwapBlock"
      class="shadow-short hidden h-full lg:block"
    />
    <TrustModalList
      v-if="displayTrustList"
      class="row-start-3 md:hidden"
      :reviews-count
      :reviews-rate
    />
    <ProductCard
      v-for="(product, index) in products"
      :key="product.listingId"
      :color-swatches="product.specifications?.color ?? []"
      :description="product.description"
      :discounted-price="product.discountedPrice"
      :image-alt="product.title"
      :image-src="product.image"
      :listing-id="product.listingId"
      :price="product.price"
      :price-new="product.priceNew"
      :review-rating="product.reviewRating"
      :specifications="
        Object.entries(product.specifications ?? {})
          .map(([key, values]) => ({
            label: key,
            values: values.map(
              ({ name, value }) => name + (value ? ` (${value})` : ''),
            ),
          }))
          .filter(({ label }) => label !== 'color')
      "
      :tags="index < 3 ? [i18n(translations.recommendationTag)] : []"
      :title="product.title"
      :url="product.productPageLink"
      @click="
        trackProductClick(product, {
          provider: 'algolia',
          list: 'landing page product',
          position: index + 1,
          source: 'search',
          queryID: querySearchInfos?.id,
          index: querySearchInfos?.index,
        })
      "
    />
    <div
      v-if="marketingContent"
      :ref="
        setupBannerImpressionTracker({
          name: 'landing_page',
          creative: marketingContent.alt,
          position: 'landing_page_3',
        })
      "
      class="md:col-span-3 lg:col-span-4"
      :class="{
        'row-start-6': displayTrustList && products.length >= 2,
        'row-start-4': !displayTrustList && products.length >= 2,
        'md:row-start-4  lg:row-start-2': products.length >= 2,
      }"
    >
      <MarketingCard
        :alt="marketingContent.alt"
        :srcLarge="marketingContent.srcLarge"
        :srcSmall="marketingContent.srcSmall"
        :url="marketingContent.url"
        @click="
          trackLandingBannerClick({
            name: 'landing_page',
            creative: marketingContent.alt,
            position: 'landing_page_3',
          })
        "
      />
    </div>
    <div
      v-if="article"
      class="md:col-span-3 lg:col-span-4"
      :class="{
        'row-start-12': displayTrustList && products.length >= 7,
        'row-start-10': !displayTrustList && products.length >= 7,
        'md:row-start-8 lg:row-start-5': products.length >= 7,
      }"
    >
      <ArticleCard
        :id="article.id"
        :category="article.category"
        :imageAlt="article.imageAlt"
        :imageSrc="article.imageSrc"
        :link="article.link"
        :text="article.text"
        :title="article.title"
        variant="full"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useState } from '#imports'
import { toRef } from 'vue'

import { useDiscountedPrice } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useDiscountedPrice'
import type { ArticleCard as ArticleCardType } from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.types'
import ArticleCard from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCard.vue'
import ProductCard from '@backmarket/nuxt-layer-recommendation/ProductCard.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useClickTracking } from '@backmarket/nuxt-layer-recommendation/useProductTracking'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import SwapBlockListing from '~/scopes/buyback/swap/components/SwapBlockListing/SwapBlockListing.vue'
import TrustModalList from '~/scopes/reassurance/components/TrustModalList/TrustModalList.vue'

import type { SearchQuery } from '../../search/composables/useProductsSearch'
import { useImpressionTracker } from '../composables/useImpressionTracker'

import MarketingCard from './MarketingCard.vue'
import translations from './ResultGrid.translations'

const { trackProductClick } = useClickTracking()

const { trackLandingBannerImpression, trackLandingBannerClick } = useTracking()
const { setupImpressionTracker: setupBannerImpressionTracker } =
  useImpressionTracker(trackLandingBannerImpression)

const querySearchInfos = useState<SearchQuery>('search-query')

const i18n = useI18n()

// AB TEST
const experiments = useExperiments()
const hasSwapBlock =
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPriceOnlyAfter' ||
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPrice'

const props = defineProps<{
  products: Product[]
  marketingContent?: {
    alt: string
    srcSmall: string
    srcLarge: string
    url: string
  }
  article?: ArticleCardType
  displayTrustList?: boolean
  reviewsCount?: number
  reviewsRate?: number
}>()

const { products } = useDiscountedPrice(toRef(props, 'products'))
</script>
