<template>
  <div v-if="rates && !ratingError && !reviewsError" id="customer-reviews">
    <ReviewsBlock
      :link="{
        name: REVIEWS_ROUTES.PAGES.LANDING,
        params: {
          uuid: id,
          slugV2: slug,
          locale,
        },
      }"
      :product-title="title"
      :rating="rates"
      :reviews="reviews?.results || []"
      :reviews-pending="pendingReviews"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

import ReviewsBlock from '~/scopes/reviews/reviews-display/components/ReviewsBlock/ReviewsBlock.vue'
import { ROUTES as REVIEWS_ROUTES } from '~/scopes/reviews/route-names'

import {
  useProductListRating,
  useProductListReviews,
} from '../composables/useProductListReviews'

const props = defineProps<{
  title: string
  id: string
  slug: string
}>()

const locale = useI18nLocale()

const { data: rates, error: ratingError } = useProductListRating(props.id)
const {
  data: reviews,
  pending: pendingReviews,
  error: reviewsError,
} = useProductListReviews(props.id)
</script>
