<template>
  <RevContainer class="pb-32 md:pb-56">
    <RevBreadcrumb
      :ariaLabel="i18n(translations.breadcrumb)"
      :breads="breadcrumb"
      @click-breadcrumb="trackClickEvent('come_back_product_breadcrumb')"
    />
    <div class="my-32 flex items-start justify-between md:my-56">
      <div>
        <h1 class="heading-1">
          {{ mainTitle }}
        </h1>
        <MainRating v-if="ratesResponse" :rating="ratesResponse" />
        <!-- This modal is displayed only in countries defined in COUNTRIES_WITH_REVIEWS_LEGAL_MODAL -->
        <ReviewsLegalModal />
      </div>

      <div
        class="hidden md:block"
        data-test="review-display-page-go-back-button"
      >
        <RevButton
          :to="backButton.link"
          variant="primary"
          @click="trackClickEvent('come_back_product')"
        >
          {{ backButton.label }}
        </RevButton>
      </div>
    </div>
    <div class="grid-standard md:gap-x-56">
      <div
        :class="[
          'order-2 md:order-1 md:col-span-12',
          { 'order-1': hasSeoText },
        ]"
      >
        <div v-if="hasSeoText" class="mt-32 md:mb-56 md:mt-0">
          <SeoText />
          <RevDivider class="my-56 hidden md:block" />
        </div>

        <div
          v-if="summaryResponse"
          class="mt-32 md:mt-0"
          :class="{
            'md:mb-32': isPhotosExperimentActive && hasReviewsWithAttachments,
            'md:mb-56': !isPhotosExperimentActive || !hasReviewsWithAttachments,
          }"
        >
          <RatingDetailsSummary :summary="summaryResponse" />

          <RevDivider
            v-if="!hasReviewsWithAttachments"
            class="my-56 hidden md:block"
          />
        </div>

        <RevSkeleton
          v-if="isPendingReviewsWithAttachments"
          class="mb-32"
          height="236px"
          shape="rectangle"
          width="100%"
        />

        <ReviewsPhotosCarousel
          v-if="isPhotosExperimentActive && hasReviewsWithAttachments"
          :reviews="reviewsWithAttachments"
        />
      </div>

      <div
        class="order-1 md:order-2 md:col-span-3"
        :class="{
          'mt-32': isPhotosExperimentActive && hasReviewsWithAttachments,
        }"
      >
        <div class="flex flex-col lg:sticky lg:top-12">
          <Sort
            id="review-page-sort-radio"
            class="mb-32 hidden lg:block"
            type="radio"
          />

          <Filters data-test="filter-list" />
        </div>
      </div>

      <div
        class="order-3 flex flex-col md:col-span-9"
        :class="{
          'mt-32': isPhotosExperimentActive && hasReviewsWithAttachments,
        }"
      >
        <RevDivider v-if="!hasReviewsWithAttachments" class="my-32 md:hidden" />

        <Sort
          id="review-page-sort-select"
          class="mb-32 lg:hidden"
          type="select"
        />

        <div ref="reviewsListObserverTarget">
          <ReviewsList
            data-test="reviews-list"
            :is-pending="isInitialFetchPending"
            :reviews="reviewsResponse"
          />

          <ClientOnly>
            <RevButton
              v-if="canFetchMoreReviews"
              class="mt-32"
              full-width="responsive"
              :loading="isFetchMorePending"
              variant="secondary"
              @click="handlFetchMoreReviews"
            >
              {{ i18n(translations.loadMore) }}
            </RevButton>
          </ClientOnly>
        </div>
      </div>
    </div>

    <PopularSearches v-if="tagsResponse" class="mt-56" :tags="tagsResponse" />
  </RevContainer>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, onBeforeUnmount, ref } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevButton } from '@ds/components/Button'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'
import { RevSkeleton } from '@ds/components/Skeleton'
import { useIntersectionObserver } from '@vueuse/core'

import { TRACKING_PAGE_TYPE_BY_ROUTE_NAME } from '~/constants/trackingPageType'
import PopularSearches from '~/scopes/product/components/PopularSearches/PopularSearches.vue'
import { useReviewsWithAttachments } from '~/scopes/reviews/reviews-display/composables/useReviewsWithAttachments'

import { ROUTES } from '../../route-names'
import Filters from '../components/Filters/Filters.vue'
import MainRating from '../components/MainRating/MainRating.vue'
import RatingDetailsSummary from '../components/RatingDetailsSummary/RatingDetailsSummary.vue'
import ReviewsLegalModal from '../components/ReviewsLegalModal/ReviewsLegalModal.vue'
import ReviewsList from '../components/ReviewsList/ReviewsList.vue'
import ReviewsPhotosCarousel from '../components/ReviewsPhotosCarousel/ReviewsPhotosCarousel.vue'
import SeoText from '../components/SeoText/SeoText.vue'
import Sort from '../components/Sort/Sort.vue'
import { useGlobalRating } from '../composables/useGlobalRating'
import { useRatingDetailsSummary } from '../composables/useRatingDetailsSummary'
import { useReviewsHead } from '../composables/useReviewsHead'
import { useReviewsList } from '../composables/useReviewsList'
import { useReviewsNavigation } from '../composables/useReviewsNavigation'
import { useReviewsTitle } from '../composables/useReviewsTitle'
import { useSeoTags } from '../composables/useSeoTags'
import { TRACKING_ZONE } from '../constants'
import translations from '../translations/reviews.translations'

const route = useRoute()
const i18n = useI18n()
const { trackClick, trackReviewBlockImpression } = useTracking()
const experiments = useExperiments()

const { mainTitle, shortTitle } = useReviewsTitle()
const { breadcrumb, backButton } = useReviewsNavigation(shortTitle)

const { ratesResponse } = useGlobalRating()
const { summaryResponse } = useRatingDetailsSummary()
const {
  fetchMoreReviews,
  isInitialFetchPending,
  isFetchMorePending,
  reviewsResponse,
  canFetchMoreReviews,
} = useReviewsList()

const tagsResponse = useSeoTags()
const { reviewsWithAttachments, isPendingReviewsWithAttachments } =
  useReviewsWithAttachments()

useReviewsHead(shortTitle, ratesResponse)

const reviewsListObserverTarget = ref<null | HTMLElement>(null)

const hasSeoText = computed(() => route.name === ROUTES.PAGES.ALL)

const pageType = computed(() => {
  const routeName = route.name?.toString() || ''

  return TRACKING_PAGE_TYPE_BY_ROUTE_NAME[routeName]
})

const isPhotosExperimentActive = computed(() => {
  return (
    experiments['experiment.photosInReview'] === 'withPhotosDefaultRelevance' ||
    experiments['experiment.photosInReview'] === 'withPhotosNewRelevance'
  )
})

const hasReviewsWithAttachments = computed(
  () =>
    !!reviewsWithAttachments.value && reviewsWithAttachments.value.length > 0,
)

const hasPhotosCarousel = computed(() =>
  !isPendingReviewsWithAttachments.value
    ? hasReviewsWithAttachments.value
    : 'pending',
)

const blockTrackingInfo = computed(() => ({
  pageType: pageType.value,
  averageRate: ratesResponse.value.averageRate,
  count: ratesResponse.value.count,
  hasPhotosCarousel: hasPhotosCarousel.value,
}))

function trackClickEvent(name: string) {
  trackClick({
    zone: TRACKING_ZONE,
    pageType: pageType.value,
    name,
  })
}

function handlFetchMoreReviews() {
  trackClickEvent('see_more_reviews')
  fetchMoreReviews()
}

const { stop } = useIntersectionObserver(
  reviewsListObserverTarget,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      trackReviewBlockImpression(blockTrackingInfo.value)
      stop()
    }
  },
)

onBeforeUnmount(() => {
  stop()
})
</script>
