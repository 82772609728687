<template>
  <section v-if="variant === 'atc'" class="flex h-full flex-col">
    <RevIllustration
      alt=""
      class="mt-4"
      data-test="intro-illustration"
      :height="244"
      src="/img/buyback/swapIntroView.svg"
      :width="387"
    />
    <h2 class="heading-3 mt-4 text-center">
      {{ i18n(translations.ad, { price: props.price }) }}
    </h2>
    <h3 class="body-2-bold mt-20 text-center">
      {{ i18n(translations.purposal) }}
    </h3>
    <div class="mt-auto">
      <RevButton
        class="mt-16"
        data-qa="user-yes"
        data-test="user-yes"
        full-width="always"
        variant="secondary"
        @click="startSwap"
      >
        <span class="heading-3 block">
          {{ i18n(translations.yes) }}
        </span>
        <span class="body-2 block">
          {{ i18n(translations.yesSubtitle) }}
        </span>
      </RevButton>
      <RevButton
        class="mt-16"
        data-qa="user-no"
        data-test="user-no"
        full-width="always"
        variant="secondary"
        @click="discardSwap"
      >
        <span class="heading-3 block">
          {{ i18n(translations.no) }}
        </span>
        <span class="body-2 block">
          {{ props.closeWording }}
        </span>
      </RevButton>
    </div>
  </section>
  <section v-else class="flex h-full flex-col">
    <div class="flex flex-col items-center gap-24 pb-60">
      <RevIllustration
        alt=""
        data-test="intro-illustration"
        :height="240"
        src="/img/buyback/swapIntroViewPlp.svg"
        :width="327"
      />
      <div>
        <h2 class="heading-1 mb-4">{{ i18n(translations.title) }}</h2>
        <h3 class="body-2">{{ i18n(translations.subTitle) }}</h3>
      </div>
      <RevList :has-external-borders="false">
        <RevListItemStatic>
          <template #prefix> <IconBadge /> </template>
          <template #label> {{ i18n(translations.listBadgeTitle) }} </template>
          <template #description>
            {{ i18n(translations.listBadgeDescription) }}
          </template>
        </RevListItemStatic>
        <RevListItemStatic>
          <template #prefix> <IconPackage /> </template>
          <template #label>
            {{ i18n(translations.listPackageTitle) }}
          </template>
          <template #description>
            {{ i18n(translations.listPackageDescription) }}
          </template>
        </RevListItemStatic>
        <RevListItemStatic>
          <template #prefix> <IconMoney /> </template>
          <template #label> {{ i18n(translations.listMoneyTitle) }}</template>
          <template #description>
            {{ i18n(translations.listMoneyDescription) }}
          </template>
        </RevListItemStatic>
      </RevList>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevList } from '@ds/components/List'
import { RevListItemStatic } from '@ds/components/ListItemStatic'
import { IconBadge } from '@ds/icons/IconBadge'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconPackage } from '@ds/icons/IconPackage'

import translations from './Intro.translations'

interface IntroProps {
  price: string
  closeWording: string
  variant?: 'atc' | 'plp'
}

const i18n = useI18n()

const props = defineProps<IntroProps>()

const emit = defineEmits(['close', 'next-step'])

function discardSwap() {
  emit('close')
}

function startSwap() {
  emit('next-step')
}
</script>
