<template>
  <div>
    <SkinnyBanner page="landing" />

    <section :class="{ 'bg-surface-default-low mb-32 pb-32': !!heroProduct }">
      <RevContainer
        :class="{
          'mb-32': !heroProduct,
        }"
      >
        <div :class="{ 'space-y-24 md:space-y-32': !!heroProduct }">
          <div class="flex items-center">
            <Breadcrumb :breads="breadcrumb" />
            <template v-if="popularRelatedLinks?.length">
              <div
                class="border-static-default-mid ml-16 h-24 border-l-2"
              ></div>
              <RelativeLinks
                class="-mr-24 md:mr-0"
                :links="popularRelatedLinks"
              />
            </template>
          </div>
          <Hero v-if="!!heroProduct" :product="heroProduct" :title />
          <TrustModalList
            v-if="displayTrustModalList"
            :class="{ 'hidden md:block': !heroProduct }"
            :reviews-count="rates?.count || 0"
            :reviews-rate="rates?.averageRate || 0"
          />
          <TrustList v-else spot="landing" />
        </div>
      </RevContainer>
    </section>
    <RevContainer data-spec="body">
      <div data-spec="main">
        <section class="mb-72" data-test="product-results-refinement">
          <div class="mb-12 justify-between lg:mb-28 lg:flex">
            <div>
              <component
                :is="!!heroProduct ? 'h2' : 'h1'"
                class="heading-1 lg:punchline"
              >
                {{
                  !!heroProduct
                    ? i18n(translations.allTitle, { title: displayedTitle })
                    : title
                }}
              </component>
              <Tagline v-if="tagline" :tagline />
            </div>
          </div>
          <TopFilters
            v-model:filters="filtersInput"
            v-model:price="priceInput"
            v-model:sort="sortInput"
            :facets
            :price-facet
            :search-nb-results="total"
            :sort-options
          />

          <div class="mt-16 lg:flex lg:gap-16">
            <main class="w-full">
              <span
                class="text-static-default-low body-2 mb-12 block lg:text-caption md:mb-16"
                data-selector="total-products"
                data-test="total-products"
              >
                {{
                  i18n(translations.resultsCount, {
                    total,
                  })
                }}
              </span>
              <SwapPill v-if="hasSwapBlock" class="lg:hidden" />

              <NoResults
                v-if="products.length === 0"
                class="my-32"
                data-spec="body"
              />
              <ResultGrid
                v-else
                :article="articles[0]"
                class="mt-8 md:mt-32"
                :display-trust-list="displayTrustModalList && !heroProduct"
                :marketingContent
                :products
                :reviews-count="rates?.count || 0"
                :reviews-rate="rates?.averageRate || 0"
              />
            </main>
          </div>

          <Pagination v-model="pageInput" :page-count />
        </section>

        <Reviews :id class="mb-72" :slug :title />
        <section class="mb-72">
          <RevSkeleton
            v-if="articlesPending"
            height="439px"
            shape="rectangle"
            width="100%"
          />
          <div v-else-if="articles?.length > 0">
            <h2 class="heading-3 mb-16">
              {{ i18n(translations.buyingGuides) }}
            </h2>

            <ArticleCardsCarousel :articles="articles.slice(1)" />
          </div>
        </section>
        <ProductTags :id class="mb-72" />
        <SEOText class="mb-72" :seo-text />
      </div>
    </RevContainer>
    <FullscreenFilters
      v-model:filters="filtersInput"
      v-model:price="priceInput"
      v-model:sort="sortInput"
      :facets
      :price-facet
      :search-nb-results="total"
      :sort-options
    />
    <!-- Trust list modals -->
    <ClientOnly>
      <SatisfactionModal :name="REASSURANCE.SATISFACTION" />
      <WarrantyModal :name="REASSURANCE.WARRANTY" />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
import { createError, useHead, useRoute, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useRouteParams } from '@backmarket/nuxt-layer-base/composables/useRouteParams'
import ArticleCardsCarousel from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCardsCarousel.vue'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { RevSkeleton } from '@ds/components/Skeleton'

import type { Features } from '~/features'
import SkinnyBanner from '~/scopes/branding/components/SkinnyBanner/SkinnyBanner.vue'
import SwapPill from '~/scopes/buyback/swap/components/SwapPill/SwapPill.vue'
import SatisfactionModal from '~/scopes/reassurance/components/SatisfactionModal/SatisfactionModal.vue'
import TrustList from '~/scopes/reassurance/components/TrustList/TrustList.vue'
import TrustModalList from '~/scopes/reassurance/components/TrustModalList/TrustModalList.vue'
import WarrantyModal from '~/scopes/reassurance/components/WarrantyModal/WarrantyModal.vue'
import { REASSURANCE } from '~/scopes/reassurance/config/modals'
import {
  getSortOptions,
  useAlgoliaApiKey,
  useAlgoliaConfiguration,
} from '~/scopes/search/helpers/helpers'
import { getMetas } from '~/utils/seo/meta'
import { buildProductSchema, prepareJsonLdScript } from '~/utils/seo/schema'

import { useProductsSearch } from '../../search/composables/useProductsSearch'
import Breadcrumb from '../components/Breadcrumb.vue'
import FullscreenFilters from '../components/FullscreenFilters.vue'
import Hero from '../components/Hero.vue'
import NoResults from '../components/NoResults.vue'
import Pagination from '../components/Pagination.vue'
import ProductTags from '../components/ProductTags.vue'
import RelativeLinks from '../components/RelativeLinks.vue'
import ResultGrid from '../components/ResultGrid.vue'
import Reviews from '../components/Reviews.vue'
import SEOText from '../components/SEOText.vue'
import Tagline from '../components/Tagline.vue'
import TopFilters from '../components/TopFilters/TopFilters.vue'
import { useArticles } from '../composables/useArticles'
import { useFiltersFromHash } from '../composables/useHashFilters'
import { useProductListPage } from '../composables/useProductListPage'
import { usePLPArticles } from '../composables/useProductListPageArticles'
import { useProductListRating } from '../composables/useProductListReviews'

import translations from './ProductListPage.translations'

const { uuid: id, slugV2: slug } = useRouteParams()
const experiments = useExperiments()

const plpConfiguration = await useProductListPage(id)
if (plpConfiguration.value === null) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Not found',
    fatal: true,
  })
}
const {
  title,
  tagline,
  seoText,
  marketingContent,
  popularRelatedLinks,
  breadcrumb,
  landingType,
  seoTitle,
  displayedTitle,
  links,
} = plpConfiguration.value

const { data: searchConfig } = await useAlgoliaConfiguration(
  'product-landing',
  id,
)

if (searchConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to retrieve search configuration',
    fatal: true,
  })
}
const { data: apiKeyConfig } = await useAlgoliaApiKey(
  searchConfig.value.indexType,
)

if (apiKeyConfig.value === null) {
  throw createError({
    statusCode: 500,
    statusMessage: 'Unable to get algolia API key',
    fatal: true,
  })
}

const sortOptions = getSortOptions(searchConfig.value.indexes)
const displayHero = landingType === 'Popular'

const {
  public: { ALGOLIA_ID },
} = useRuntimeConfig()

const {
  total,
  products,
  heroProduct,
  facets,
  pageCount,
  priceFacet,
  filtersInput,
  sortInput,
  pageInput,
  priceInput,
} = await useProductsSearch(
  ALGOLIA_ID,
  searchConfig.value,
  apiKeyConfig.value.apiKey,
  '',
  displayHero,
)

useFiltersFromHash(filtersInput, priceInput, pageInput)

const FF_ARTICLE_ENDPOINT = computed(() => {
  const { countryCode } = useMarketplace().market
  const enabledCountries =
    useRuntimeConfig().public.FF_NEW_ARTICLES_ENDPOINT_ENABLED_BY_COUNTRY || ''

  return enabledCountries.includes(countryCode)
})

const { pending: articlesPendingOld, data: articlesOld } = usePLPArticles(id)
const { fetchArticles, articles: articlesNew } = useArticles()
await fetchArticles()

const articles = computed(() =>
  FF_ARTICLE_ENDPOINT.value ? articlesNew.value : articlesOld.value,
)
const articlesPending = computed(() =>
  FF_ARTICLE_ENDPOINT.value ? false : articlesPendingOld.value,
)

const i18n = useI18n()
const route = useRoute()
const locale = useI18nLocale()
const pageNumber = route.query.p
const metaTitle = pageNumber
  ? i18n(translations.seoTitle, { title: seoTitle, pageNumber })
  : seoTitle
const { data: rates } = await useProductListRating(id)
const metaImageUrl = '/img/plp/ogImage.png'
const { features } = useMarketplace<Features>()
const seoDescription = i18n(translations.metaDescription, {
  coolingOffDays: features.coolingOffDays,
  productType: displayedTitle,
  warrantyDuration: features.warranty.duration,
})

// AB test
const displayTrustModalList =
  experiments['experiment.newReassuranceBanner'] === 'new'
const hasSwapBlock =
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPriceOnlyAfter' ||
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPrice'

const {
  public: { COMPANY_NAME },
} = useRuntimeConfig()
useHead({
  title: metaTitle,
  titleTemplate: `%s | ${COMPANY_NAME}`,
  meta: getMetas({
    title: `${metaTitle} | ${COMPANY_NAME}`,
    description: seoDescription,
    image: metaImageUrl,
    'og:type': 'product',
  }),
  link: Object.values(links)
    .map((item) => ({
      rel: 'alternate',
      hreflang: item.params.locale,
      href: item.href,
    }))
    .concat({
      href: route.fullPath,
      hreflang: locale,
      rel: 'canonical',
    }),
  script: [
    prepareJsonLdScript(
      buildProductSchema({
        title: metaTitle,
        description: seoDescription,
        rate: rates.value ?? { averageRate: NaN, count: NaN },
        image: metaImageUrl,
        isOutOfStock: products.value.length === 0,
        price: products.value[0]?.price,
        companyName: COMPANY_NAME,
        brand: COMPANY_NAME,
      }),
    ),
  ],
})
</script>
