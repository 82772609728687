<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    data-test="modal-swap-offer"
    :has-back-button="false"
    :name="modalName"
    :title="i18n(translations.titleModal)"
    @open="handleModalOpen"
  >
    <template #body>
      <section class="mb-24 flex h-full flex-col gap-24">
        <RevIllustration
          alt=""
          class="self-center"
          :height="200"
          src="/img/buyback/viewOffer.svg"
          :width="201"
        />
        <div class="flex flex-col gap-4">
          <div class="flex justify-between">
            <h2 class="heading-3">Your offer</h2>
            <RevButtonBase @click="editOffer">
              <span class="body-2-tabular"
                >{{ i18n(translations.edit) }}
              </span></RevButtonBase
            >
          </div>
          <RevCard v-if="buybackOffer" class="flex items-center gap-16 p-24">
            <IconSwap
              class="border-static-default-low rounded-md shrink-0 border"
              size="32"
            />
            <div class="flex flex-col">
              <span class="body-1-bold">
                {{
                  i18n(translations.tradeinValue, {
                    price: i18n.price(buybackOffer.listing.price),
                  })
                }}</span
              >
              <span class="body-2"> {{ buybackOffer.product.fullTitle }} </span>
            </div>
          </RevCard>
        </div>
        <RevStatusTracker
          :current-step="steps[0].key"
          orientation="vertical"
          :steps
        >
          <template v-for="step in steps" #[`${step.key}-date`]>
            <p v-if="step.date" :key="step.date">
              {{ step.date }}
            </p>
          </template>
        </RevStatusTracker>
        <div class="pb-12 text-center">
          <RevButtonBase @click="deleteOffer">
            <span class="body-1-link">{{ i18n(translations.delete) }}</span>
          </RevButtonBase>
        </div>
      </section>
    </template>
    <template #footer>
      <RevButton full-width="always" variant="primary" @click="continueFlow">
        {{ i18n(translations.continue) }}
      </RevButton>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { closeModal } from '@ds/components/ModalBase'
import { RevStatusTracker } from '@ds/components/StatusTracker'
import { IconSwap } from '@ds/icons/IconSwap'

import translations from './ViewOfferModal.translations'

const i18n = useI18n()
const { deleteBuybackOffer, buybackOffer } = useBuybackOffer()
const tracking = useTracking()

const props = defineProps<{
  modalName: string
}>()

const emit = defineEmits(['edit'])

async function deleteOffer() {
  await deleteBuybackOffer()
  closeModal(props.modalName)

  tracking.trackClick({
    zone: 'plp_tradein_how_it_works',
    name: 'delete_tradein',
  })
}

function continueFlow() {
  closeModal(props.modalName)
}

function editOffer() {
  closeModal(props.modalName)
  emit('edit')

  tracking.trackClick({
    zone: 'plp_tradein_how_it_works',
    name: 'edit_tradein',
  })
}
const steps = [
  {
    key: 'getOffer',
    date: i18n(translations.statusOfferDate),
    label: i18n(translations.statusOfferData),
  },
  {
    key: 'transferData',
    date: '',
    label: i18n(translations.statusTransferData),
  },
  {
    key: 'shipFree',
    date: i18n(translations.statusShipDate),
    label: i18n(translations.statusShipData),
  },
  {
    key: 'getPaid',
    date: i18n(translations.statusPaidDate),
    label: i18n(translations.statusPaidData),
  },
]

function handleModalOpen() {
  tracking.trackModal({
    name: 'PLP_TRADEIN_INFO',
    status: 'opened',
  })
}
</script>
