export default {
  buyingGuides: {
    id: 'landing_buying_guides',
    defaultMessage: 'Read our guides',
  },
  prevSlideText: {
    id: 'buying_guide_slider_prev_slide',
    defaultMessage: 'Display previous slide',
  },
  nextSlideText: {
    id: 'buying_guide_slider_next_slide',
    defaultMessage: 'Display next slide',
  },
  metaDescription: {
    id: 'landing_meta_description',
    defaultMessage:
      'Find the best deals on the refurbished {productType}. Up to 70% off compared to new ✌ Free delivery ✅ Cheap {productType} ✅ {warrantyDuration} year warranty ✅ {coolingOffDays}-day money-back guarantee',
  },
  seoTitle: {
    id: 'landing_seo_title',
    defaultMessage: '{title} - Page {pageNumber}',
  },
  allTitle: {
    id: 'landing_all_title',
    defaultMessage: 'All {title} options',
  },
  resultsCount: {
    id: 'landing_page_filters_products_new_plural',
    defaultMessage: `{total, plural,
      one {# product}
      other {# products}
    }`,
  },
}
