<template>
  <component :is="iconComponent" v-if="success" :class="iconClass" :size />

  <div
    v-else-if="pending"
    :class="placeholderSizeClass"
    data-test="dynamic-icon-placeholder"
  />
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue'

import { type SizeNames } from '@ds/components/Icon'

import type { DynamicIconProps } from './DynamicIcon.types'
import { useColorClass } from './helpers/useColorClass'
import { useImportIconComponent } from './helpers/useImportIconComponent'
/**
 * TODO: add unit tests to this component
 */

const props = withDefaults(defineProps<DynamicIconProps>(), {
  size: 'small',
  color: 'primary',
  extraClass: '',
})

const colorClass = useColorClass(toRefs(props).color)
const iconClass = computed(() => `${colorClass.value} ${props.extraClass}`)

const ICON_SIZE_TO_SIZE_CLASS: Record<SizeNames, string> = {
  'extra-small': 'w-12 h-12',
  small: 'w-16 h-16',
  medium: 'w-24 h-24',
  large: 'w-48 h-48',
}

const sizeClass = computed(() => ICON_SIZE_TO_SIZE_CLASS[props.size])
const placeholderSizeClass = computed(
  () => `${sizeClass.value} ${props.extraClass}`,
)

const {
  icon: iconComponent,
  success,
  pending,
} = useImportIconComponent(toRefs(props).icon)
</script>
