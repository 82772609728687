<template>
  <RevDivider v-if="reviews" class="my-24 md:my-32" />

  <UserCommentModal
    :displayProductLink
    :pictures="selectedReview.pictures"
    :review="selectedReview"
    :reviews-ids
    :track-review-impression="true"
    @switch="switchToReview"
  >
    <template #trigger="{ open }">
      <RevCardCarousel
        v-if="reviews"
        desktop-card-width="narrow"
        mobile-card-width="narrow"
        :next-alternative-text="i18n(translations.alternativeNext)"
        :prev-alternative-text="i18n(translations.alternativePrevious)"
        size="extra-small"
        :title="i18n(translations.title)"
      >
        <div v-for="review in formattedReviews" :key="review.id" class="h-full">
          <RevCard
            class="h-full cursor-pointer hover:drop-shadow-xl"
            @click="handleReviewCardClick(open, { review })"
          >
            <div class="relative h-160">
              <RevIllustration
                alt=""
                class="rounded-lg h-full w-full object-cover"
                :height="160"
                :src="review.pictures[0]"
                :width="160"
              />

              <div
                class="rounded-lg absolute bottom-0 left-0 right-0 top-0"
                :class="styles.darkOverlay"
              />

              <RevTag
                class="absolute left-16 top-16 !max-w-[128px]"
                :label="`${review.customer.firstName} ${review.customer.lastName}`"
                variant="secondary"
              />

              <div
                class="mood-inverse absolute bottom-16 left-16 right-16 flex flex-col gap-16"
              >
                <RevRating :score="review.averageRate" size="extra-small" />
              </div>
            </div>
          </RevCard>
        </div>
      </RevCardCarousel>
    </template>
  </UserCommentModal>

  <RevDivider v-if="reviews" class="mt-4 md:mt-12" />
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed, ref, useCssModule } from 'vue'

import UserCommentModal from '@backmarket/nuxt-layer-reviews/review-display/UserCommentModal.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevRating } from '@ds/components/Rating'
import { RevTag } from '@ds/components/Tag'

import { PRODUCT } from '~/scopes/product/route-names'
import { ROUTES } from '~/scopes/reviews/route-names'

import type { DisplayedReviewWithAttachments } from '../../models/reviews'

import translations from './ReviewsPhotosCarousel.translations'

type FormattedReviews = DisplayedReviewWithAttachments & {
  reviewDate: string
  purchaseDate: string
  pictures: string[]
}

const route = useRoute()

const i18n = useI18n()
const styles = useCssModule()
const { trackClick } = useTracking()

const props = defineProps<{
  reviews: DisplayedReviewWithAttachments[]
}>()

const displayProductLink =
  route.name !== ROUTES.PAGES.PRODUCT && route.name !== PRODUCT.HOME

const formattedReviews = computed(() => {
  return props.reviews.map((review: DisplayedReviewWithAttachments) => ({
    ...review,
    reviewDate: review.createdAt,
    purchaseDate: review.product.purchasedAt,
    pictures: review.attachments?.map(({ url }) => url) ?? [],
  }))
})

const reviewsIds = computed(() => formattedReviews.value.map((r) => r.id))

const selectedReview = ref(formattedReviews.value[0])

function handleReviewCardClick(
  openModal: () => void,
  { review }: { review: FormattedReviews },
) {
  selectedReview.value = review
  openModal()
}

function switchToReview(reviewId: string | null) {
  if (!reviewId) return

  const newReview = formattedReviews.value.find(
    (review) => review.id === reviewId,
  )

  if (newReview) {
    selectedReview.value = newReview

    trackClick({
      zone: 'reviews_controller',
      page_type: route.name as string,
    })
  }
}
</script>

<style module>
.darkOverlay {
  background: linear-gradient(180deg, rgba(29, 32, 36, 0) 49.5%, #1d2024 80.5%);
}
</style>
