<template>
  <div class="h-full">
    <section
      class="shadow-short rounded-md flex h-full flex-col px-16 py-32"
      :class="[
        buybackOffer ? 'bg-static-success-low' : 'bg-action-default-brand-mid',
      ]"
    >
      <header class="mb-12 flex gap-12">
        <div class="rounded-md inline-block p-8" :class="iconColors">
          <IconCheckLarge v-if="buybackOffer" size="24" />
          <IconSwap v-else size="24" />
        </div>

        <p v-if="buybackOffer" class="heading-1">
          {{ i18n.price(buybackOffer?.listing?.price || '') }}
        </p>
      </header>

      <h3 class="heading-3">
        {{
          buybackOffer ? i18n(translations.caption) : i18n(translations.title)
        }}
      </h3>

      <p class="body-2 mb-16">
        {{ blockText }}
      </p>

      <div v-if="!buybackOffer" class="mt-auto">
        <RevButton
          class="mb-12"
          data-test="swap-plp-cta"
          full-width="always"
          variant="secondary"
          @click="getAnOffer"
        >
          {{ i18n(translations.buttonCta) }}
        </RevButton>
        <button
          class="body-1 w-full text-center underline"
          data-test="swap-plp-info"
          @click="seeHowItWorks"
        >
          {{ i18n(translations.buttonInfo) }}
        </button>
      </div>
      <div v-else class="mt-auto">
        <button
          class="body-1 w-full text-center underline"
          data-test="swap-plp-see"
          @click="openViewOfferModal"
        >
          {{ i18n(translations.buttonSee) }}
        </button>
      </div>
    </section>
    <SwapModal
      datalayer-category=""
      :intro
      :modal-name="MODAL_NAMES.SWAP_LISTING"
      price=""
      @close-with-offer="handleCloseWithOffer"
    />
    <ViewOfferModal
      :modal-name="MODAL_NAMES.SWAP_VIEW_OFFER"
      @close="handleCloseViewOffer"
      @edit="openSwapModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'

import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'
import { openModal } from '@ds/components/ModalBase'
import { IconCheckLarge } from '@ds/icons/IconCheckLarge'
import { IconSwap } from '@ds/icons/IconSwap'

import { MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapModal from '~/scopes/buyback/swap/components/SwapModal/SwapModal.vue'

import ViewOfferModal from '../ViewOfferModal/ViewOfferModal.vue'

import translations from './SwapBlockListing.translations'

const i18n = useI18n()
const { buybackOffer } = useBuybackOffer()
const { trackClick, trackSwap } = useTracking()
const intro = ref<'plp'>()
const fromOffer = ref(false)

const props = withDefaults(
  defineProps<{
    trackingLabel?: string
  }>(),
  {
    trackingLabel: 'swap_from_plp',
  },
)

const iconColors = computed(() =>
  buybackOffer.value
    ? tw`bg-static-success-low-hover text-action-success-hi`
    : tw`text-static-brand-mid bg-[#E2CCF4]`,
)

const blockText = computed(() =>
  buybackOffer.value
    ? i18n(translations.blockTextWithOffer, {
        device: buybackOffer.value.product.shortTitle || '',
      })
    : i18n(translations.blockTextWithoutOffer),
)

function openSwapModal(introName: 'plp' | undefined = undefined) {
  trackSwap({
    label: props.trackingLabel,
  })

  intro.value = introName
  fromOffer.value = true

  openModal(MODAL_NAMES.SWAP_LISTING)
}

function openViewOfferModal() {
  trackClick({
    zone: 'product_list',
    name: 'tradein_info',
  })

  openModal(MODAL_NAMES.SWAP_VIEW_OFFER)
}

function handleCloseViewOffer() {
  fromOffer.value = false
}

function getAnOffer() {
  trackClick({
    zone: 'product_list',
    name: 'get_a_tradein_offer',
  })
  openSwapModal()
}

function seeHowItWorks() {
  trackClick({
    zone: 'product_list',
    name: 'tradein_info',
  })
  openSwapModal('plp')
}

async function handleCloseWithOffer() {
  if (fromOffer.value === true) {
    // We need to be sure the previous modal has been closed and unmounted
    // otherwise we'll enter a loop
    await nextTick()
    openModal(MODAL_NAMES.SWAP_VIEW_OFFER)
  }
}
</script>
