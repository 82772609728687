export const STEPS = {
  INTRO: 'intro',
  CATEGORIES: 'categories',
  QUESTIONS: 'questions',
  OFFER: 'offer',
  NO_OFFER: 'no_offer',
} as const

export const SWAP_INFO_MESSAGE = {
  GET_CATEGORIES:
    '[BUYBACK][API][SWAP] Error getting questions API (categories)',
  GET_QUESTIONS: '[BUYBACK][API][SWAP] Error getting questions API',
  EMPTY_QUESTIONS: '[BUYBACK][API][SWAP] Empty response from questions API',
  POST_QUESTIONS: '[BUYBACK][API][SWAP] Error posting questions API',
  GET_OFFER: '[BUYBACK][API][SWAP] Error getting offer',
  ADD_SWAP: '[BUYBACK][API][SWAP] Error adding buyback to cart',
  SWAP_MODAL: '[BUYBACK][JS][SWAP] Error in swap modal',
  VIEW_SWAP_STEP: '[BUYBACK][VIEW][SWAP] View step in swap',
  SWAP_BLOCK: '[BUYBACK][JS][SWAP] Error in swap block',
  SWAP_STEP: '[BUYBACK][JS][SWAP] Error in swap step',
  SWAP_POPULAR: '[BUYBACK][API][SWAP] Error in swap popular searches',
  SWAP_SEARCH: '[BUYBACK][API][SWAP] Error in swap search',
}

export const STATUS_CODE_NO_OFFER = 204
